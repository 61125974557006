import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { isRtl } from '@/plugins/i18n';

const toastOptions = {
  hideProgressBar: true,
  position: POSITION.BOTTOM_CENTER,
  timeout: 3000,
  rtl: isRtl(),
};

export { Toast, toastOptions };
