<script setup lang="ts">
import LangSelector from '@/components/common/LangSelector.vue';
import { getTokenData, logout } from '@/plugins/keycloak';
import { HeaderAndMenu } from '@efficacity/components-library-vuejs3';
import { RouterView } from 'vue-router';
import '@efficacity/components-library-vuejs3/style.css';
import { useI18n } from '@/plugins/i18n';
import { ref } from 'vue';

const { t } = useI18n();

const email = ref<string>('');

getTokenData().then((data) => {
  email.value = data.email;
});
</script>

<template>
  <v-app full-height>
    <v-layout>
      <HeaderAndMenu>
        <template #title>
          <router-link to="/">
            <img
              src="@/assets/img/EnergyMapper_logo.png"
              alt="EnergyMapper_logo"
              class="headband__logo"
            />
          </router-link>
        </template>

        <template #actions>
          <LangSelector />
          <span class="headband__mail">
            {{ email }}
          </span>

          <button
            class="headband__logout-link"
            :title="t('headband.logout')"
            type="button"
            @click="logout()"
          >
            <v-icon class="headband__logout-icon"> mdi-logout </v-icon>
          </button>
        </template>

        <template #menuList>
          <ul>
            <li>
              <router-link to="/"> {{ t('global.backToHome') }} </router-link>
            </li>
          </ul>
        </template>

        <template #menuInfos>
          {{ t('headband.infos') }}
          <br />
          <a href="mailto:energymapper@efficacity.com"> energymapper@efficacity.com </a>
        </template>
      </HeaderAndMenu>

      <v-main scrollable>
        <RouterView />
      </v-main>
    </v-layout>
  </v-app>
</template>

<style lang="scss">
html {
  overflow: hidden;
  font-size: 16px;
  font-family: 'Lato', Arial, sans-serif;
}

#app {
  .v-main {
    @include backgroundAndColor('background');
  }

  .headband__logo {
    height: 50px;
  }
}
</style>
