import { createPinia } from 'pinia';
import { createApp } from 'vue';
import 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import App from '@/App.vue';
import * as i18n from '@/plugins/i18n';
import * as keycloak from '@/plugins/keycloak';
import { Toast, toastOptions } from '@/plugins/vue-toastification';
import { createVuetify } from '@/plugins/vuetify';
import { createRouter } from '@/router';
import * as Sentry from '@sentry/vue';

const {
  VITE_ENV: ENV,
  VITE_SENTRY_ENV: SENTRY_ENV,
  VITE_SENTRY_DSN: SENTRY_DSN,
  VITE_SENTRY_RELEASE: SENTRY_RELEASE,
  VITE_USE_API_MOCKS: USE_API_MOCKS,
} = import.meta.env;

const app = createApp(App);

const router = createRouter();

if (ENV !== 'local') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (USE_API_MOCKS !== 'true') {
  await keycloak.initialize();
}

app.use(await i18n.initialize());
app.use(createPinia());
app.use(createVuetify(i18n.getLocale()));
app.use(Toast, toastOptions);
app.use(router);

app.mount('#app');
