<template>
  <svg
    width="24"
    height="24"
    fill="currentColor"
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5.3371 2.0381v3.3371h-3.3371v1.4051h3.3371v3.3622h1.4218v-3.3622h3.3371v-1.4051h-3.3371v-3.3371zm10.538 1.6154-6.8363 5.7922h-0.0449v12.516h5.6632v-3.2652h1.9725v3.2652h5.3708v-12.516h-0.04037z"
    />
  </svg>
</template>
