import addBuilding from '@/components/icons/AddBuilding.vue';
import { h } from 'vue';

const aliases = {
  addBuilding,
};

const customIcons = {
  component: (props) => h(aliases[props.icon]),
};

// eslint-disable-next-line import/prefer-default-export
export { customIcons };
