import * as vueRouter from 'vue-router';

export function createRouter() {
  return vueRouter.createRouter({
    history: vueRouter.createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'Project list',
        component: () =>
          import(/* webpackChunkName: "project-list-view" */ '@/views/ProjectList.vue'),
      },
      {
        path: '/project/:id',
        name: 'Studies list',
        component: () =>
          import(/* webpackChunkName: "studies-list-view" */ '@/views/StudiesList.vue'),
      },
      {
        path: '/new-project/',
        name: 'New project',
        component: () =>
          import(/* webpackChunkName: "new-project-view" */ '@/views/CreateEditProject.vue'),
      },
      {
        path: '/edit-project/:id',
        name: 'Edit project',
        component: () =>
          import(/* webpackChunkName: "edit-project-view" */ '@/views/CreateEditProject.vue'),
      },
      {
        path: '/new-study/:projectId',
        name: 'new study',
        component: () =>
          import(/* webpackChunkName: "new-study-view" */ '@/views/CreateEditStudy.vue'),
      },
      {
        path: '/edit-study/:projectId/:studyId',
        name: 'Edit study',
        component: () =>
          import(/* webpackChunkName: "edit-study-view" */ '@/views/CreateEditStudy.vue'),
      },
      {
        path: '/thermal-study/:studyId',
        name: 'Thermal study map page',
        component: () =>
          import(/* webpackChunkName: "thermal-map-view" */ '@/views/ThermalMapPage.vue'),
      },
      {
        path: '/electric-study/:studyId',
        name: 'Electric study map page',
        component: () =>
          import(/* webpackChunkName: "electric-map-view" */ '@/views/ElectricMapPage.vue'),
      },
      {
        path: '/study/:studyId/simulations',
        name: 'Simulations',
        component: () =>
          import(/* webpackChunkName: "results-list-view" */ '@/views/SimulationList.vue'),
      },
      {
        path: '/study/:studyId/simulations/:simulationId',
        name: 'ThermalResultPage',
        component: () =>
          import(/* webpackChunkName: "thermal-result-view" */ '@/views/ThermalResultPage.vue'),
      },
      {
        path: '/study/:studyId/electric-simulations/:simulationId',
        name: 'ElectricResultPage',
        component: () =>
          import(
            /* webpackChunkName: "electric-result-view" */ '@/views/ElectricResultPage.vue'
          ),
      },
    ],
  });
}
