/**
 * Internationalization based on vue-i18n
 * ======================================
 *
 * Settings and translation files
 * ------------------------------
 *
 * Below are defined the supported locales and their settings (RTL, datetime formats).
 *
 * The translated messages are in `src/languages/[locale].ts` files. For message parameters, use
 * the named interpolation:
 *
 *   {
 *     welcome: 'Bienvenue {firstName}!'
 *   }
 *
 * Do not use the list interpolation like 'Bienvenue {0}!'.
 *
 * Usage in modules or components
 * ------------------------------
 *
 * `useI18n()` behaves the same as vue-i18n. See also `getLocale()` and `isRtl()`.
 *
 * Component unit tests
 * --------------------
 *
 * In the context of unit tests, `getLocale()` returns 'test' and `useI18n()` is mocked to print
 * the raw keys, or a JSON if they accept parameters:
 *
 * t('someKey')
 * > 'someKey'
 *
 * t('anotherKey', {foo: 'bar'})
 * > '{"t":"anotherKey","foo":"bar"}'
 *
 * This makes is easier to write assertions in your test cases.
 */

import en from '@/languages/en';
import fr from '@/languages/fr';
import { ref } from 'vue';
import { createI18n, useI18n as defaultUseI18n, type Composer, type I18n } from 'vue-i18n';

const envIsTest = process.env.NODE_ENV === 'test';

const allLocales = ['en', 'fr'];
const defaultLocale = 'fr';
const rtlLocales: string[] = ['ar'];

const datetimeFormats = {
  fr: {
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
};

// Read the current locale from the browser's localStorage, setting it to the default if it is
// not set or invalid
function getLocale(): string {
  if (envIsTest) {
    return 'test';
  }

  let locale = localStorage.getItem('locale');
  if (locale === null || !allLocales.includes(locale)) {
    locale = defaultLocale;
    localStorage.setItem('locale', defaultLocale);
  }
  return locale;
}

function switchLocaleAndReload(targetLocale: string) {
  if (envIsTest) {
    throw new Error('cannot switch locale in unit tests (requires a page reload)');
  }

  if (!allLocales.includes(targetLocale)) {
    throw new Error(`Invalid target locale '${targetLocale}'`);
  }
  localStorage.setItem('locale', targetLocale);
  window.location.reload();
}

// Is the current locale right-to-left?
function isRtl(): boolean {
  if (envIsTest) {
    return false;
  }

  return rtlLocales.includes(getLocale());
}

const i18nRef = ref<I18n>();
// Asynchronously load the language file corresponding to the current locale, initialize vue-i18n
// and set the document's `lang` attribute
async function initialize() {
  if (envIsTest) {
    throw new Error('cannot initialize i18n in unit tests (requires a window reload)');
  }

  const locale = getLocale();

  const messages = { fr, en };

  document.querySelector('html')!.setAttribute('lang', locale);

  i18nRef.value = createI18n({
    legacy: false,
    locale,
    messages,
    datetimeFormats: datetimeFormats as any,
  });
  return i18nRef.value;
}

function getMockI18n() {
  return {
    t: (key: string, params?: number | Record<string, unknown>) => {
      if (typeof params === 'undefined') {
        return key;
      } else if (typeof params === 'number') {
        return JSON.stringify({ t: key, count: params });
      } else {
        return JSON.stringify({ t: key, ...params });
      }
    },
    d: (date: Date, format?: string) => {
      if (typeof format === 'undefined') return date.toString();
      return JSON.stringify({ t: date, format });
    },
    te: () => true,
  };
}

function useI18n(): Composer {
  if (envIsTest) {
    return getMockI18n() as any as Composer;
  }
  if (i18nRef.value) {
    return i18nRef.value.global as Composer;
  }
  return defaultUseI18n();
}

export { allLocales, getLocale, switchLocaleAndReload, initialize, isRtl, useI18n };
