const fr = {
  global: {
    backToHome: "Retour à l'accueil",
    cancel: 'Annuler',
    close: 'Fermer',
    cold: 'froid',
    create: 'Créer',
    delete: 'Supprimer',
    edit: 'Editer',
    export: 'Exporter',
    finish: 'Terminer',
    heating: 'chaud',
    import: 'Importer',
    no: 'Non',
    remove: 'Supprimer',
    reset: 'réinitialiser',
    save: 'Enregistrer',
    supportEmail: 'energymapper-support@efficacity.com',
    validate: 'Valider',
    warning: 'Attention',
    yes: 'Oui',
  },
  entityTypes: {
    AutoConsumptionZone: "Zone d'autoconsommation",
    Building: 'bâtiment | bâtiments',
    DistrictLimit: 'périmètre | périmètres',
    network: 'Reseaux de chaleur et/ou de froid',
    District: 'Quartier | Quartiers',
    source: 'source | sources',
    highConsumptionBuilding: 'Bâtiment gros consommateur',
    equivalentBuilding: 'Bâtiment équivalent',
  },
  entities: {
    building: {
      addCustomThermalLoad: 'Ajouter courbe de charge',
      create: 'Créer le bâtiment',
      dataOrigin: 'Origine infos bâtiment',
      dataOriginValues: {
        custom: 'Import manuel utilisateur',
        imported: 'Import automatique BDTOPO',
      },
      floorArea: 'Surface (m²)',
      floorCount: "Nombre d'étages",
      hasSolarPanels: 'Panneaux photovoltaïques installés',
      load: 'Courbe de charge électrique',
      name: 'Nom',
      purpose: 'Utilisation',
      purposeValues: {
        individual_housing: 'Logement individuel',
        collective_housing: 'Logement collectif',
        office: 'Bureau',
        school: 'École',
        shop: 'Commerce',
      },
      year: 'Année de construction',
    },
    autoConsumptionZone: {
      name: 'Nom',
    },
    photovoltaicPanel: {
      albedo: 'Albedo',
      batteryCapacity: 'Capacité de la batterie (kWh)',
      batteryInvestmentCost: "Coût d'investissement de la batterie (€/kWh)",
      connectionLossesAndMissmatchCoeficient:
        'Coefficient de perte par connectique et missmatch',
      horizontalAngle: "Angle avec l'horizontale (°)",
      inverterEfficiency: "Efficacité de l'onduleur",
      lowLightEfficiencyCorrection: 'Coefficient de transmission-absorption',
      lowLightEfficiencyCorrectionTooltip:
        'Coefficient de transmission-absorption solaire des modules',
      nominalInverterPower: "Puissance nominale de l'onduleur (W)",
      panelPeakPower: 'Puissance crête par panneau PV (W)',
      panelSurface: "Surface d'un panneau PV (m²)",
      select: 'Installer panneaux solaires',
      southAngle: 'Angle avec le sud (°)',
      studyCostInvestmentRatio: "Ratio du CAPEX pour le coût maitrise d'œuvre",
      temperatureCoefficient: 'Coefficient de température (/°C)',
      temperatureCoefficientTooltip:
        'Coefficient de température de la puissance crête telle que définie dans CEI61215 et CEI61646',
      thermalConfinement: 'Degré de confinement thermique',
      thermalConfinementTooltip: 'Degré de confinement thermique de la face arrière des modules',
      thermalEquilibriumTemperature: "Température d'équilibre thermique (°C)",
      thermalEquilibriumTemperatureTooltip:
        "Température d'équilibre thermique du module telle que définie dans CEI61215 et CEI61646",
    },
    equivalentBuilding: {
      buildingAdded: 'Bâtiment ajouté au bâtiment équivalent {equivalentBuildingName}',
      equivalentSurface: 'Surface totale (m²)',
      modificationWarning:
        'Les courbes modifiées seront appliquées à tous les bâtiments du type correspondant.',
    },
    electricSubstation: {
      tooltip: 'Poste HT/BT',
    },
    highConsumptionBuilding: {
      purposes: {
        collective_housing: 'Logement collectif',
        commercial_center: 'Centre commercial',
        hospital: 'Hôpital',
        swimming_pool: 'Piscine',
      },
    },
  },
  units: {
    MWh_by_year: 'MWh/an',
    kW: 'kW',
  },
  sourceToDistrict: {
    distribution: {
      investmentByUnitLength: 'Coût du raccord par mètre linéaire (€/m)',
      length: 'Distance source - bâtiments (m)',
    },
    exploitationConstraints: {
      askForStorage: 'Option stockage thermique',
      askForStorageDetail:
        "Simulation d'une variante avec stockage thermique dédié à la source (i.e. on aura des combinaisons avec et sans stockage thermique pour cette source)",
      endDateSummer: "Dernier jour de l'été",
      maxCoverageRateByYear: 'Taux de couverture annuel maximum',
      minCoverageRateByYear: 'Taux de couverture annuel minimum',
      minExploitedTimeByYear: 'Heures de fonctionnement annuel minimum',
      minLoadRate: 'Taux de charge horaire minimum',
      minLoadRateDetail:
        "Taux de charge minimal acceptable pour le générateur (pompe à chaleur ou chaudière) afin d'éviter le fonctionnement marche arrêt",
      minSuccessiveHours: 'Heures de fonctionnement successif minimum',
      minSuccessiveHoursDetail:
        "Nombre minimum d'heures successives qu'une source doit délivrer, évitant de la mobiliser pour peu d'heures de fonctionnement en accord avec un pilotage réaliste)",
      startDateSummer: "Premier jour de l'été",
      summerOff: 'Arrêt estival',
    },
    exploitationConstraintsUnit: {
      minLoadRate: 'taux de charge moyen',
      minSuccessiveHours: 'heures de fonctionnement successif moyen',
    },
    heatExchanger: {
      efficiency: 'Efficacité échangeur sous-station',
      investmentByKW: 'CAPEX échangeur (€/kW)',
      operatingRatio: 'Ratio du CAPEX pour la maintenance annuelle',
    },
    heatPump: {
      electricityCostByKWh: 'Coût électricité (€/kWh)',
      operatingRatio: 'Ratio du CAPEX pour la maintenance annuelle',
    },
    source: {
      biomass: {
        forestPotential: 'Potentiel total extractible forêt (kWh/an)',
        woodDeliveryCost: 'Coût du transport du bois (€/(kWh.km))',
        woodDeliveryDistance: 'Distance moyenne parcourue par le bois (m)',
        woodPurchaseCost: 'Coût de la plaquette forrestière (€/kWh)',
      },
      biomethane: {
        biomethanePotential: 'Potentiel biométhanisation (GWh/an)',
      },
      coldStorage: {
        investmentByKW: 'CAPEX échangeur récupération (€/kW)',
        storageTemperature: {
          label: 'Type de froid',
          negative: 'Négatif',
          positive: 'Positif',
          positive_negative: 'Positif-Négatif',
        },
        volume: 'Volume du local (m3)',
      },
      datacenter: {
        certification: {
          label: 'Classification du Data Center',
          tier_1: 'Tier I',
          tier_2: 'Tier II',
          tier_3: 'Tier III',
          tier_4: 'Tier IV',
        },
        coolingEfficiency: "Classement d'efficacité du refroidissement",
        surface: 'Surface totale du Data Center (m²)',
        surfaceIt: 'Surface local serveurs (m²)',
      },
      geothermalGroundwater: {
        depth: 'Profondeur du puisage (m)',
        flowRate: 'Débit nappe (m³/h)',
        investmentByDepth: 'CAPEX (€/mètre de puisage)',
      },
      geothermalProbe: {
        depth: 'Profondeur des sondes (m)',
        geothermalConductivity: 'Valeur de la conductivité thermique (W/m)',
        investmentByDepth: 'CAPEX (€/mètre de sonde)',
      },
      industrialWasteHeat: {
        activityCodeIcpe: "Code ICPE du process principal de l'entreprise",
        activityCodeNaf: "Code NAF du process principal de l'entreprise",
        powerHighTemperature: 'Puissance source haute température (W)',
        powerLowTemperature: 'Puissance source basse température (W)',
      },
      methanation: {
        methanationPotential: 'Potentiel de méthanation (GWh/an)',
      },
      pyrogasification: {
        pyrogasificationPotential: 'Potentiel de pyrogazéification (GWh/an)',
      },
      sewageSanitationNetwork: {
        meanRate: 'Débit moyen des eaux usées (m³/jour)',
      },
      sewageTreatmentPlant: {
        maxRate: 'Débit entrant maximum (m³/jour)',
        maxSewageCapacity: 'Capacité maximum (m³)',
        meanRate: 'Débit entrant moyen (m³/jour)',
        meanSewageCapacity: 'Capacité moyenne (m³)',
      },
      solarThermal: {
        investmentByUnitArea: 'CAPEX (€/m² de panneau)',
        panelsSizingMethod: {
          interseasonal: 'Intersaisonier',
          label: 'Méthode de dimensionnement du stockage thermique',
          summer: 'Été',
        },
      },
      wasteIncinerationPlant: {
        lowerHeatingValue: 'PCI moyen des déchets (MJ/kg)',
        weightByYear: 'Masse de déchets traités (tonnes/an)',
      },
    },
    sourceToDistrict: {
      studyCostInvestmentRatio: "Ratio du CAPEX pour le coût maitrise d'œuvre ",
    },
    storage: {
      investmentByUnitVolume: 'CAPEX (€/m³ de stockage)',
    },
  },
  errors: {
    unknown: "Une erreur s'est produite",
    get: {
      address: "Erreur lors de la recherche d'adresse",
      building: 'Erreur lors de la récupération des données des bâtiments',
      buildingImportZoneTooLarge:
        "Zone d'import des bâtiments trop large, veuillez sélectionner une zone plus restreinte",
      customThermalLoad: 'Erreur lors de la récupération des courbes de charges personnalisées',
      district: 'Erreur lors de la récupération du périmètre',
      districtLimit: 'Erreur lors de la récupération des données du quartier',
      electricSubstation: 'Erreur lors de la récupération des postes HT/BT',
      highConsumptionBuildings:
        'Erreur lors de la récupération des bâtiments gros consommateurs',
      entity: 'Erreur lors de la récupération des données',
      importBuildings: 'Erreur lors de la récupération des données des bâtiments',
      json: 'Erreur lors de la récupération du json',
      networks: 'Erreur lors de la récupération des réseaux de chaleur et/ou froid.',
      primaryData: 'Erreur lors de la récupération des données',
      project: 'Erreur lors de la récupération de projet',
      simulations: 'Erreur lors de la récupération des simulations',
      source: 'Erreur lors de la récupération des données des sources',
      sourcesData: 'Erreur lors de la récupération des données des sources',
      sourceToDistrict: 'Erreur lors de la récupération des données des sources',
      studyThermalLoad: 'Erreur lors de la récupération des besoins du quartier',
      thermalNetworks: 'Erreur lors de la récupération des réseaux de chaleur et de froid',
    },
    create: {
      building: 'Erreur lors de la création de bâtiment',
      customThermalLoad: 'Erreur lors de la création de la courbe de charge personnalisée',
      district: 'Erreur lors de la création de périmètre',
      project: 'Erreur lors de la création de projet',
      simulation: 'Erreur lors du lancement de la simulation',
      source: 'Erreur lors de la création de la source',
      sourceToDistrict: 'Erreur lors de la création de la source',
      study: "Erreur lors de la création de l'étude",
    },
    edit: {
      district: {
        'simulation in cooling mode without cooling needs':
          'Veuillez décocher la case "Simulation pour alimenter un réseau de froid" pour un usage sans Climatisation.',
        'simulation in heating mode without heating needs':
          'Veuillez cocher la case "Simulation pour alimenter un réseau de froid" pour un usage Climatisation seulement.',
        unknown: 'Erreur lors de la mise à jour du quartier',
      },
      project: {
        unknown: "Erreur lors de l'édition de projet",
      },
      sourceToDistrict: {
        unknown: 'Erreur lors de la mise à jour de la source',
      },
      unknown: "Une erreur s'est produite lors de la mise à jour.",
    },
    delete: {
      building: 'Erreur lors de la suppression du batiment',
      customThermalLoad: 'Erreur lors de la suppression de la courbe de charge personnalisée',
      project: 'Erreur lors de la suppression de projet',
      sourceToDistrict: 'Erreur lors de la suppression de la source',
      source: 'Erreur lors de la suppression de la source',
      study: "Erreur lors de la suppression de l'étude",
    },
    input: {
      date: 'Veuillez saisir une date correcte (mm-jj)',
      isBetween: 'Veuillez saisir un entier compris entre {min} et {max}',
      isFloatBetween: 'Veuillez entrer un nombre entre {min} et {max}.',
      isFloatAbove: 'Veuillez entrer un nombre inférieur à {max}.',
      isFloatBelow: 'Veuillez entrer un nombre supérieur à {min}.',
      positiveFloat: 'Veuillez saisir un nombre positif',
      positiveInteger: 'Veuillez saisir un entier positif',
      rate: 'Veuillez saisir une valeur comprise entre 0 et 1',
      required: 'Veuillez remplir ce champ',
    },
    action: {
      unsupported: 'Action non implémentée',
    },
  },
  warnings: {
    delete: {
      project:
        'Vous êtes sur le point de supprimer votre projet {name}. Cela supprimera également toutes les études et résultats de simulation associés. Êtes vous sûr\xa0?',
      study:
        'Vous êtes sur le point de supprimer votre étude {name}. Cela supprimera également tous les résultats de simulation associés. Êtes vous sûr\xa0?',
    },
  },
  headband: {
    infos: 'Si vous rencontrez un problème, contactez nous',
    contactUs: 'contactez nous',
    logout: 'Se déconnecter',
  },
  home: {
    addProject: '+ Ajouter un projet',
    openProject: 'Ouvrir le projet',
    projectsList: 'Liste des projets ({nb})',
  },
  createEditProject: {
    addProject: 'Ajouter un nouveau projet',
    editProject: 'Editer un projet',
    projectInfos: 'Informations du projet',
    nameLabel: 'Nom du projet',
    namePlaceholder: 'Saisir un nom',
    descriptionLabel: 'Description du projet',
    descriptionPlaceholder: 'Saisir une description',
    localizationLabel: 'Localisation du projet',
    localizationPlaceholder: 'Chercher un lieu (ville, adresse...)',
  },
  project: {
    project: 'Projet {name}',
    addMasterPlan: '+ Ajouter un plan masse',
    addStudy: '+ Créer une nouvelle étude',
    openAllTabs: 'Tout ouvrir',
    closeAllTabs: 'Tout fermer',
    seeMasterPlan: 'Ouvrir plan masse',
    seeStudy: "Ouvrir l'étude",
    resultNb: '0 résultat | 1 résultat | {nb} résultats',
    availableViews: 'Vue disponible | Vues disponibles',
    resultBtn: {
      noResult: 'Aucun résultat',
      success: 'Ouvrir résultat',
      pending: 'Simulation en attente',
      failure: 'Erreur lors de la simulation',
    },
    seeSimulations: 'Voir les simulations',
  },
  study: {
    label: 'étude',
    types: {
      thermal: 'Thermique',
      electric: 'Électrique',
    },
  },
  simulationList: {
    title: 'Liste des simulations',
    seeResults: 'Voir les résultats de la simulation',
    simulation: 'simulation',
    simulationPending: 'Simulation en attente',
    simulationRunning: 'Simulation en cours',
    simulationFailed: 'Echec de la simulation',
    backToMap: 'Retour à la carte',
    backToStudies: 'Retour aux études',
  },
  createEditStudy: {
    addStudy: 'Ajouter une nouvelle étude',
    descriptionLabel: "Description de l'étude",
    descriptionPlaceholder: 'Saisir une description',
    editStudy: 'Editer une étude',
    nameLabel: "Nom de l'étude",
    namePlaceholder: 'Saisir un nom',
    studyInfos: "Informations de l'étude",
    studyTypeLabel: "Type d'Étude",
    studyTypePlaceholder: "Selectionner un type d'étude",
  },
  mapPage: {
    mapLeftMenu: {
      categories: {
        AutoConsumptionZone: "Zones d'autoconsommation",
        Building: 'Bâtiments',
        District: 'Périmètre du quartier',
        Source: 'Sources',
      },
      actions: {
        addNewDistrictPerimeter: 'Ajouter une nouvelle zone',
        drawNewBuilding: 'Tracer un bâtiment personnalisé',
        drawNewDistrict: 'Créer le périmètre',
        importBuildings: 'Importer les bâtiments',
        newBuilding: 'Ajouter un nouveau bâtiment',
        newZone: 'Ajouter une nouvelle zone',
      },
    },
    mapRightMenu: {
      cancel: 'Annuler',
      delete: 'Supprimer',
      select: 'Sélectionner',
      selectMultiplePotentials: 'Sélectionner les potentiels',
      update: 'Sauvegarder',
      validate: 'Valider',
    },
    mapTiles: 'Fond de carte :',
    mapLegend: 'Légende de la carte :',
    sourceTypes: {
      biomass: 'Biomasse',
      biomethane: 'Biométhanisation',
      cold_storage: 'Entrepôt frigorifique',
      datacenter: 'Datacenter',
      geothermal_groundwater: 'Géothermie sur nappe',
      geothermal_probe: 'Géothermie sur sonde',
      industrial_waste_heat: 'Chaleur fatale industrielle',
      methanation: 'Méthanation',
      pyrogasification: 'Pyrogazéification',
      sewage_sanitation_network: "Réseau d'assainissement des eaux usées",
      sewage_treatment_plant: "Station d'épuration",
      solar_thermal: 'Solaire thermique',
      waste_incineration_plant: "Usine d'incinération des déchets",
    },
    networkTypes: {
      coldNetwork: 'Réseau de froid',
      hotNetwork: 'Réseau de chaud',
      coldNetworkPoint: 'Centre du réseau de froid',
      hotNetworkPoint: 'Centre du réseau de chaud',
    },
    listOf: {
      District: 'Quartier',
      DistrictLimit: 'Périmètre du quartier',
      Building: 'Liste des bâtiments',
      source: 'Liste des sources disponibles',
      network: 'Reseau de chaleur/froid',
    },
    possibleActions: 'Actions possibles',
    drawPopinTitle: {
      District: 'Créer le périmètre',
      Building: 'Créer un ou plusieurs bâtiments',
      AutoConsumptionZone: "Créer une zone d'autoconsommation",
    },
    drawPolygonOnMap: 'Tracez un polygone sur la carte en placant des marqueurs',
    removeLastPoint: 'Retirer le dernier point',
    zoomIn: 'Zoomer',
    zoomOut: 'Dézoommer',
    simulationPopin: {
      continue: 'Accepter',
      errors: {
        cases: {
          geoThermalProbeWithoutUse:
            'La géothermie sur sondes nécessite la présence de climatisation pour régénérer le ' +
            "gisement sollicité l'hiver en injectant dans les sondes la chaleur à évacuer de " +
            "la climatisation l'été. Sélectionner climatisation dans la définition du quartier " +
            'et saisir ensuite un ou des bâtiments ayant des besoins de froid non nuls.',
          heatingSourcesForCoolingUse:
            'Les sources sélectionnées suivantes {sourcesNames} ne conviennent pas pour alimenter un réseau de froid.',
          noPhotovoltaicPanels:
            'Equiper au moins un toit de panneaux PV dans la zone {zones} avant de lancer les calculs.|Equiper au moins un toit de panneaux PV dans les zones {zones} avant de lancer les calculs.',
          noSources: 'Sélectionner au moins une source ENR&R.',
          noThermalLoadsNeeds: 'Saisir un ou plusieurs bâtiments ayant des besoins de {type}.',
          thermalLoadsMissing:
            'Pas de courbe de charge par défaut, saisir des courbes de charge personnalisées pour bâtiment de typologies {purposes}.',
        },
        description:
          "L'erreur suivante a été rencontrée :|Les erreurs suivantes ont été rencontrées :",
        title: 'Erreurs au lancement de la simulation',
      },
      launchSimulation: 'Lancer la simulation',
      simulationConstraintsTitle:
        "Vous êtes sur le point de lancer une simulation avec les contraintes d'exploitation suivantes :",
      title: 'Vous êtes sur le point de lancer une simulation avec les paramètres suivants :',
    },
    importBuildingsPopin: {
      confirmation:
        'Vous allez importer les bâtiments de la BDTOPO dans la zone délimitée par le tracé du quartier précédement défini. Êtes vous sûr?',
      importSummaryTitle: 'Bâtiments importés',
      nothingImported: "Aucun nouveau bâtiment n'a été trouvé.",
    },
    SimulationConstraintsTable: {
      constraintHeader: 'Contrainte',
      title: "Contraintes d'exploitation",
    },
    SimulationParametersTable: {
      equivalentBuildingsTable: {
        title: 'Bâtiments équivalents',
        headers: {
          name: 'Typologie',
          equivalentSurface: 'Surface totale (m²)',
          purpose: 'Utilisation',
          year: 'Année de construction',
        },
        purpose: {
          individual_housing: 'Logement individuel',
          collective_housing: 'Logement collectif',
          office: 'Bureau',
          school: 'École',
          shop: 'Commerce',
        },
      },
      districtTable: {
        title: 'Quartier',
        headers: {
          cooling_temperature: 'Température de la climatisation (°C)',
          heating_temperature: "Température de l'eau chaude (°C)",
          simulationMode: 'Mode de simulation',
          totalCoolingLoad: 'Besoins totaux de climatisation (MWh/an)',
          totalHeatingLoad: 'Besoins totaux de chauffage (MWh/an)',
          uses: 'Usages',
        },
        uses: {
          heating: 'Chauffage',
          cooling: 'Climatisation',
          dhw: 'Eau chaude sanitaire',
        },
        simulationMode: {
          heating: 'Chauffage',
          cooling: 'Climatisation',
        },
      },
      sourceToDistrictTable: {
        title: 'Sources sélectionnées',
        headers: {
          name: 'Nom',
          sourceType: 'Type de source',
          askForStorage: 'Stockage demandé',
        },
      },
    },
    CustomThermalLoad: {
      climateZone: 'Zone climatique',
      climateZoneHint: 'La zone climatique du projet sera utilisée',
      createCustomThermalLoad: 'Créer nouvelle courbe de charge',
      name: 'Nom',
      title: 'Nouvelle courbe de charge personnalisée',
      unit: 'unité',
      uploadFile: 'Courbe de charge au format json (tableau de 8760 valeurs)',
      uploadFileFormat:
        'Spécifier le besoin du bâtiment au pas de temps horaire sur une année (soit 8760 valeurs) en Watt ou en Watt par m²',
    },
  },
  mapRightMenu: {
    selectMultiplePotentials: {
      selectedPotential: 'Potentiel énergétique annuel bois (MWh/an): {potential}',
      districtNeeds: 'Besoins de chaleur annuels (MWh/an): {needs}',
      selectedPotentialRatio: 'Soit un ratio Potentiel/Besoins de valeur de {ratio}',
    },
    warningDubious:
      'La base de données ICPE semble mal renseignée pour cette source. Le profil généré est donc à prendre avec précaution.',
    toggleMenuBtn: {
      true: 'Fermer le menu',
      false: 'Ouvrir le menu',
    },
    multiSelectionPopinContent: 'Valider la sélection multiple actuelle\xa0?',
    openVectorPopin: 'Ouvrir la modale',
    launchSimulationBtn: 'Lancer une simulation',
    measureBtn: 'Mesurer sur la carte',
    districtNeeds: 'Besoins totaux du quartier',
    conditionNotMet: {
      noDistrictLoad: 'Pas de besoins de quartier renseignés',
    },
    buildingForm: {
      addCustomThermalLoad: 'Ajouter courbe de charge',
      customThermalLoad: {
        heating: 'Courbe de charge de chauffage',
        cooling: 'Courbe de charge de climatisation',
        dhw: 'Courbe de charge de eau chaude sanitaire',
      },
      defaultThermalLoad: 'Courbe de charge par défaut',
      delete: 'Supprimer',
      deleteCustomThermalLoad: 'Supprimer la courbe de charge',
      name: 'Nom',
      nonMatchingThermalLoad: 'Courbe de charge différentes',
      purpose: {
        label: 'Utilisation',
        choices: {
          individual_housing: 'Logement individuel',
          collective_housing: 'Logement collectif',
          office: 'Bureau',
          school: 'École',
          shop: 'Commerce',
        },
      },
      submit: 'Enregistrer',
      year: 'Année de construction',
    },
    districtForm: {
      cooling_mode: 'Simulation pour alimenter un réseau de froid',
      cooling_temperature: 'Température de la climatisation',
      title: 'Caractéristiques du quartier',
      heating_temperature: {
        label: "Température de l'eau chaude",
        value: '{temperature}°C',
      },
      uses: {
        choices: {
          cooling: 'Climatisation',
          dhw: 'Eau chaude sanitaire',
          electric: 'Électricité',
          heating: 'Chauffage',
        },
        detail:
          "Même pour simuler un réseau de froid, il est utile de signifier les besoins de climatisation si l'on souhaite tester une goéthermie sur sondes car celle-ci nécessite une production de froid l'été pour régénérer le gisement.",
        label: 'Usage',
      },
    },
    sourceToDistrictCard: {
      create: 'Sélectionner',
      update: 'Enregistrer',
      delete: 'Désélectionner',
      selectSourceLocation: 'Définir la localisation',
      selectSourcePotentials: 'Sélectionner les potentiels',
      createSourceToDistrictWithMultiplePotentials: 'Valider la sélection',
      sourceSubtext: {
        biomass:
          'Pour une construction optimale des scénarios avec la biomasse, sélectionner un potentiel à peu près équivalent aux besoins du quartier en modulant le nombre de carreaux choisis',
        geothermalGroundwater:
          'Visitez https://www.geothermies.fr/viewer/?al=autolayer_ressource_surface_ouvert pour trouver la profondeur et le débit à la localisation souhaitée.',
        geothermalGroundwaterWarning:
          'Attention, saisir une profondeur entre 50 m minimum et 3000 m maximum et le débit entre 50 m3/h et 22 000 m³/h​',
        geothermalProbe:
          'Positionner la source sur la carte là où le potentiel est le plus fort et là où sera le local pompe à chaleur',
        sewageSanitationNetwork:
          'Positionner la source sur la carte là où sera le local pompe à chaleur',
      },
    },
    sourceToDistrictForm: {
      categories: {
        distribution: 'Distribution',
        exploitationConstraints: "Contraintes d'exploitations",
        heatExchanger: 'Échangeur thermique',
        heatPump: 'Système thermodynamique',
        source: 'Source',
        sourceToDistrict: 'Paramètres généraux du projet',
        storage: 'Stockage',
      },
    },
    seeSimulations: 'Voir les simulations',
    sourceDetails: {
      fieldNames: {
        activityCodeIcpe: 'Activité principale',
        activityCodeNaf: 'Activité principale',
        averageExtractibleVolume: 'Volume de plaquette potentiel extractible (m³/an)',
        biomethanePotential: 'Potentiel de biométhanisation (GWh/an)',
        buildingSurface: 'Surface du bâtiment (m²)',
        buildingVolume: 'Volume du bâtiment (m³)',
        cantonName: 'Nom du canton',
        dataSource: 'Origine des données',
        departmentName: 'Département',
        flowRate: 'Débit moyen entrant (m³/j)',
        forestSurface: 'Surface de la zone forestière (m²)',
        forestZoneId: 'Zone forestière',
        geothermalConductivity: 'Conductivité thermique (W/m.K)',
        geothermalDepth: 'Profondeur de la nappe (m)',
        geothermalFlowRate: 'Débit (m³/heure)',
        itSurface: 'Surface de la salle IT (m²)',
        location: 'Localisation',
        mainTreeType: 'Essence majoritaire',
        methanationPotential: 'Potentiel de méthanation sélectionné (GWh/an)',
        name: 'Nom',
        potentialGeothermalEnergy: 'Potentiel extractible (W/m)',
        power_high_temperature_mw: 'Puissance haute température (MW)',
        power_high_temperature_kw: 'Puissance haute température (kW)',
        power_low_temperature_mw: 'Puissance basse température (MW)',
        power_low_temperature_kw: 'Puissance basse température (kW)',
        pyrogasificationPotential: 'Potentiel de pyrogazéification sélectionné (GWh/an)',
        temperature: 'Niveau de température (°C)',
        totalSelectedPotential: 'Potentiel énergétique annuel (kWh/an)',
        wasteCapacity: 'Capacité de déchets annuelle (tonne/an)',
        year: 'Année de dernière mise à jour',
      },
      dataNeeded: {
        default:
          "Veuillez renseigner l'information manquante avant de sélectionner cette source.",
        industrial_waste_heat:
          'Pour pouvoir sélectionner cette source, il est nécessaire de renseigner au moins une valeur de puissance. Le premier champs correspond à la somme des puissances des équipements dégageant de la chaleur fatale à plus de 90°C, le second à la somme des puissances des équipements dégageant de la chaleur fatale à moins de 90°C.',
      },
      fieldValues: {
        activityCodeIcpe: {
          2210: "Abattage d'animaux",
          2275: 'Fabrication de levure',
          2265: 'Fermentation acetique en milieu liquide',
          2220: "Preparation de produits alimentaires d'origine vegetale",
          2253: 'Preparation, conditionnement de boissons',
          2252: 'Preparation, conditionnement de cidre',
          2251: 'Preparation, conditionnement de vins',
          2250: 'Production par distillation d alcools de bouche d origine agricole',
          2230: 'Reception, stockage, traitement, transformation etc. du lait',
          2420: 'Fabrication de charbon de bois',
          2440: 'Fabrication de papier carton',
          2430: 'Preparation de la pate à papier',
          2631: 'Extraction par la vapeur des parfums, huiles essentielles',
          2620: 'Fabrication de composes organiques sulfures',
          2630: 'Fabrication de ou à base de detergents et savons',
          2610: "Fabrication industrielle par transformation chimique d'engrais",
          2740: "Incineration de cadavres d'animaux de compagnie",
          2750: "Station d'epuration collective d'eaux residuaires industrielles",
          2752: "Station d'epuration mixte",
          2771: 'Traitement thermique de dechets non dangereux',
          2770: 'Traitement thermique de dechets dangereux',
          2910: 'Installation de combustion',
          2971: "Installation de production de chaleur ou d'électricité à partir de déchets",
          2915: 'Procedes de chauffage',
          2921: 'Refroidissement evaporatif par dispersion d eau dans un flux d air',
          2562: 'Chauffage et traitement industriels par bains de sels fondus',
          2545: "Fabrication d'acier, fer, fonte, ferro-alliage",
          2520: 'Fabrication de ciments, chaux, platres',
          2523: 'Fabrication de produits ceramiques et refractaires',
          2547: 'Fabrication de silico-alliages ou carbure de silicium',
          2542: 'Fabrication du coke',
          2530: 'Fabrication et travail du verre',
          2551: 'Fonderie de metaux et alliages ferreux',
          2552: 'Fonderie de metaux et alliages non-ferreux',
          2550: 'Fonderie de produits moules ... contenant du plomb',
          2525: 'Fusion de matieres minerales',
          2567: 'Galvanisation, etamage de metaux',
          2521: 'Station d enrobage au bitume de materiaux routiers',
          2546: 'Traitement industriel des minerais non ferreux, elaboration des metaux et alliages non ferreux',
          2561: 'Trempe recuit, revenu des metaux et alliages',
          2321: 'Atelier de fabrication de tissus',
          2340: 'Blanchisserie, laverie de linge',
          2315: 'Fabrication de fibres vegetales artificielles',
          2350: 'Tanneries, megisseries',
          2330: 'Teinture, impression, appret, enduction, blanchiment et delavage de matieres textiles',
          9999: 'Divers',
        },
        activityCodeNaf: {
          '4621Z':
            "Commerce de gros de céréales, de tabac non manufacturé, de semances et d'aliments pour le bétail",
          '3530Z': "Production et distribution de vapeur et d'air conditionné",
          '5210B': 'Entreposage et stockage non frigorifique',
          '1610A': 'Sciage et rabotage du bois, hors imprégnation',
          '2561Z': 'Traitement et revêtement des métaux',
          '1091Z': "Fabrication d'aliments pour animaux de fermes",
          '1013A': 'Préparaton industrielle de produits à base de viande',
          '7010Z': 'Activité des sièges sociaux',
          '2222Z': "Fabrication d'emballages en matières plastiques",
          '2932Z': "Fabrication d'autres équipements automobiles",
          '2221Z': 'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
          '3832Z': 'Récupération de déchets triés',
          '3511Z': "Production d'électricité",
          '2120Z': 'Fabrication de préparations pharmaceutiques',
          '6820B': "Location de terrains et d'autres bien immobiliers",
          '1623Z': "Fabrication de charpentes et d'autres menuiseries",
          '2014Z': "Fabrication d'autres produits chimiques organiques de base",
          '2399Z': "Fabrication d'autres produits minéraux non métalliques n.c.a.",
          '2016Z': 'Fabrication de matières plastiques de base',
          '1721A': 'Fabrication de carton ondulé',
          '3821Z': 'Traitement et élimination des déchets non dangereux',
          '1011Z': 'Transformation et conservation de la viande de boucherie',
          '5210A': 'Entreposage et stockage frigorifique',
          '2030Z': 'Fabrication de peintures, vernis, encres et mastics',
          '3101Z': 'Fabrication de meubles de bureau et de magasin',
          '2013B': "Fabrication d'autres produits chimiques inorganiques de base n.c.a",
          '3109B': "Fabrication d'autres meubles et industries connexes de l'ameublement",
          '2042Z': 'Fabrication de parfums et de produits pour la toilette',
          '1621Z': 'Fabrication de placage et de panneaux de bois',
          '2011Z': 'Fabrication de gaz industriels',
          '8610Z': 'Activités hospitalières',
          '1624Z': "Fabrication d'emballages en bois",
          '2592Z': "Fabrication d'emballages métalliques légers",
          '0146Z': 'Élevage de porcins',
          '1812Z': 'Autre imprimerie (labeur)',
          '2219Z': "Fabrication d'autres articles en caoutchouc",
          '4941A': 'Transports routiers de fret interurbains',
          '4711F': 'Hypermarchés',
          '2059Z': "Fabrication d'autres produits chimiques n.c.a.",
          '2920Z': 'Fabrication de carrosseries et remorques',
          '4675Z': 'Commerce de gros  de produits chimiques',
          '0812Z': "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
          '2562B': 'Mécanique industrielle',
          '2511Z': 'Fabrication de structures métalliques et de parties de structures',
          '4617A': "Centrales d'achat alimentaires",
          '0141Z': 'Élevage de vaches laitières',
          '2229A': 'Fabrication de pièces techniques à base de matières plastiques',
          '2830Z': 'Fabrication de machines agricoles et forestières',
          '2015Z': "Fabrication de produits azotés et d'engrais",
          '2910Z': 'Construction de véhicules automobile',
          '2223Z': "Fabrication d'éléments en matières plastiques pour la construction",
          '1020Z': 'Transformation et conservation de poisson, de crustacés et de mollusques',
          '7219Z': 'Recherche-développement en autres sciences physiques et naturelles',
          '2550B': 'Découpage, emboutissage',
          '3811Z': 'Collecte des déchets non dangereux',
          '1629Z':
            "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
          '4633Z':
            'Commerce de gros  de produits laitiers, œufs, huiles et matières grasses comestibles',
          '2512Z': 'Fabrication de portes et fenêtres en métal',
          '1712Z': 'Fabrication de papier et de carton',
          '3822Z': 'Traitement et élimination des déchets dangereux',
          '3030Z': 'Construction aéronautique et spatiale',
          '1107A': 'Industrie des eaux de table',
          '0147Z': 'Élevage de volailles',
          '3102Z': 'Fabrication de meubles de cuisine',
          '2825Z': "Fabrication d'équipements aérauliques et frigorifiques industriels",
          '1721B': 'Fabrication de cartonnages',
          '0150Z': 'Culture et élevage associés',
          '4332A': 'Travaux de menuiserie bois et pvc',
          '2822Z': 'Fabrication de matériel de levage et de manutention',
          '5229B': 'Affrètement et organisation des transports',
          '2211Z': 'Fabrication et rechapage de pneumatiques',
          '1920Z': 'Raffinage du pétrole',
          '3700Z': 'Collecte et traitement des eaux usées',
          '4639B': 'Commerce de gros  alimentaire non spécialisé',
          '0111Z':
            "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
          '2562A': 'Décolletage',
          '1051C': 'Fabrication de fromage',
          '2110Z': 'Fabrication de produits pharmaceutiques de base',
          '8411Z': 'Administration publique générale',
          '4910Z': 'Transport ferroviaire interurbain de voyageurs',
          '1061A': 'Meunerie',
          '2712Z': 'Fabrication de matériel de distribution et de commande électrique',
          '2052Z': 'Fabrication de colles',
          '4671Z': 'Commerce de gros  de combustibles et de produits annexes',
          '4941B': 'Transports routiers de fret de proximité',
          '6311Z': 'Traitement de données, hébergement et activités connexes',
          '2012Z': 'Fabrication de colorants et de pigments',
          '1012Z': 'Transformation et conservation de la viande de volaille',
          '2751Z': "Fabrication d'appareils électroménagers",
          '2811Z':
            "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
          '2599B': "Fabrication d'autres articles métalliques",
          '8292Z': 'Activités de conditionnement',
          '1041A': "Fabrication d'huiles et graisses raffinées",
          '1085Z': 'Fabrication de plats préparés',
          '3299Z': 'Autres activités manufacturières n.c.a.',
          '7112B': 'Ingénierie, études technique',
          '0113Z': 'Culture de légumes, de melons, de racines et de tubercules',
          '1396Z': "Fabrication d'autres textiles techniques et industriels",
          '2020Z': "Fabrication de pesticides et d'autres produits agrochimiques",
          '6420Z': 'Activités des sociétés holding',
          '2229B': 'Fabrication de produits de consommation courante en matières plastiques',
          '6832A': "Administration d'immeubles et autres biens immobiliers",
          '2313Z': 'Fabrication de verre creux',
          '3020Z': 'Construction de locomotives et d autre matériel ferroviaire roulant',
          '1089Z': "Fabrication d'autres produits alimentaires n.c.a.",
          '0142Z': "Élevage d'autres bovins et de buffles",
          '1041B': "Fabrication d'huiles et graisses raffinées",
          '4931Z': 'Transports urbains et suburbains de voyageurs',
          '1721C': "Fabrication d'emballages en papier",
          '2420Z':
            'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier',
          '4649Z': "Commerce de gros (commerce interentreprises) d'autres biens domestiques",
          '1610B': 'Imprégnation du bois',
          '2892Z': "Fabrication de machines pour l'extraction ou la construction",
          '8299Z': 'Autres activités de soutien aux entreprises n.c.a.',
          '2051Z': 'Fabrication de produits explosifs',
          '1723Z': "Fabrication d'articles de papeterie",
          '2829B': "Fabrication d'autres machines d'usage général",
          '7120B': 'Analyses, essais et inspections techniques',
          '4673A':
            'Commerce de gros (commerce interentreprises) de bois et de matériaux de construction',
          '2711Z': 'Fabrication de moteurs, génératrices et transformateurs électriques',
          '0610Z': 'Extraction de pétrole brut',
          '2733Z': "Fabrication de matériel d'installation électrique",
          '3250A': 'Fabrication de matériel médico-chirurgical et dentaire',
          '4669B':
            'Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers',
          '3523Z': 'Commerce de combustibles gazeux par conduites',
          '4672Z': 'Commerce de gros (commerce interentreprises) de minerais et métaux',
          '1106Z': 'Fabrication de malt',
          '2720Z': "Fabrication de piles et d'accumulateurs électriques",
          '4391A': 'Travaux de charpente',
          '2813Z': "Fabrication d'autres pompes et compresseurs",
          '4631Z': 'Commerce de gros (commerce interentreprises) de fruits et légumes',
          '1092Z': "Fabrication d'aliments pour animaux de compagnie",
          '1622Z': 'Fabrication de parquets assemblés',
          '4638A':
            'Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques',
          '3521Z': 'Production de combustibles gazeux',
          '1722Z': "Fabrication d'articles en papier à usage sanitaire ou domestique",
          '8129B': 'Autres activités de nettoyage n.c.a.',
          '2529Z': "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
          '4711B': "Commerce d'alimentation générale",
          '3812Z': 'Collecte des déchets dangereux',
          '4950Z': 'Transports par conduites',
          '4211Z': 'Construction de routes et autoroutes',
          '0161Z': 'Activités de soutien aux cultures',
          '4532Z': 'Commerce de détail d équipements automobiles',
          '1061B': 'Autres activités du travail des grains',
          '1051A': 'Fabrication de lait liquide et de produits frais',
          '3513Z': "Distribution d'électricité",
          '4619A': "Centrales d'achat non alimentaires",
          '2740Z': "Fabrication d'appareils d'éclairage électrique",
          '1081Z': 'Fabrication de sucre',
          '2364Z': 'Fabrication de mortiers et bétons secs',
          '4623Z': "Commerce de gros (commerce interentreprises) d'animaux vivants",
          '1310Z': 'Préparation de fibres textiles et filature',
          '2593Z': "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
          '1395Z': 'Fabrication de non-tissés',
          '4677Z': 'Commerce de gros (commerce interentreprises) de déchets et débris',
          '2521Z': 'Fabrication de radiateurs et de chaudières pour le chauffage central.',
          '2573B': "Fabrication d'autres outillages",
          '9499Z': 'Autres organisations fonctionnant par adhésion volontaire',
          '2041Z': 'Fabrication de savons, détergents et produits d entretien',
          '8532Z': 'Enseignement secondaire technique ou professionnel',
          '4676Z':
            "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires",
          '2362Z': "Fabrication d'éléments en plâtre pour la construction",
          '0811Z':
            "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
          '2365Z': "Fabrication d'ouvrages en fibre-ciment",
          '4511Z': 'Commerce de voitures et de véhicules automobiles légers.',
          '3316Z': "Réparation et maintenance d'aéronefs et d'engins spatiaux",
          '1082Z': 'Fabrication de cacao, chocolat et de produits de confiserie',
          '3012Z': 'Construction de bateaux de plaisance',
          '4730Z': 'Commerce de détail de carburants en magasin spécialisé',
          '2814Z': "Fabrication d'autres articles de robinetterie",
          '3230Z': "Fabrication d'articles de sport",
          '2611Z': 'Fabrication de composants électroniques',
          '1073Z': 'Fabrication de pâtes alimentaires',
          '1062Z': 'Fabrication de produits amylacés.',
          '2053Z': "Fabrication d'huiles essentielles",
          '8121Z': 'Nettoyage courant des bâtiments',
          '2572Z': 'Fabrication de serrures et de ferrures',
          '1101Z': 'Fabrication de boissons alcooliques distillées',
          '0899Z': 'Autres activités extractives n.c.a',
          '2433Z': 'Profilage à froid par formage ou pliage',
          '5221Z': 'Services auxilaires des transports terrestres',
          '3600Z': "Captage, traitement et distribution d'eau",
          '5229A': 'Messagerie, fret express',
          '2351Z': 'Fabrication de ciment',
          '3312Z': 'Réparation de machines et équipements mécaniques',
          '4752B':
            'Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m² et plus)',
          '7490B': 'Activités spécialisées, scientifiques et techniques diverses',
          '5813Z': 'Édition de journaux',
          '3514Z': "Commerce d'électricité",
          '4674A': 'Commerce de gros (commerce interentreprises) de quincaillerie',
          '2017Z': 'Fabrication de caoutchouc synthétique',
          '1084Z': 'Fabrication de condiments et assaisonnements',
          '2599A': "Fabrication d'articles métalliques ménagers",
          '3103Z': 'Fabrication de matelas',
          '2651A': "Fabrication d'équipements d'aide à la navigation",
          '0220Z': 'Exploitation forestière',
          '2651B': "Fabrication d'instrumentation scientifique et technique",
          '4690Z': 'Commerce de gros (commerce interentreprises) non spécialisé',
          '4322B': "Travaux d'installation d'équipements thermiques et de climatisation",
          '4778B': 'Commerces de détail de charbons et combustibles',
          '4332C': 'Agencement de lieux de vente',
          '2060Z': 'Fabrication de fibres artificielles ou synthétiques',
          '4519Z': "Commerce d'autres véhicules automobiles",
          '1051D': "Fabrication d'autres produits laitiers",
          '4520B': "Entretien et réparation d'autres véhicules automobiles",
          '2530Z':
            "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
          '2732Z': "Fabrication d'autres fils et câbles électroniques ou électriques",
          '8122Z': 'Autres activités de nettoyage des bâtiments et nettoyage industriel',
          '4618Z': "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
          '8790B':
            'Hébergement social pour adultes et familles en difficultés et autre hébergement social',
          '2352Z': 'Fabrication de chaux et plâtre',
          '2841Z': 'Fabrication de machines-outils pour le travail des métaux',
          '2361Z': "Fabrication d'éléments en béton pour la construction",
          '2899B': "Fabrication d'autres machines spécialisées",
          '2790Z': "Fabrication d'autres matériels électriques",
          '4754Z': "Commerce de détail d'appareils électroménagers en magasin spécialisé",
          '1729Z': "Fabrication d'autres articles en papier ou en carton",
          '2931Z': "Fabrication d'équipements électriques et électroniques automobiles",
          '2812Z': "Fabrication d'équipements hydrauliques et pneumatiques",
          '2445Z': 'Métallurgie des autres métaux non ferreux',
          '4638B': 'Commerce de gros (commerce interentreprises) alimentaire spécialisé divers',
          '5224B': 'Manutention non portuaire',
          '1512Z': "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
          '6110Z': 'Télécommunications filaires',
          '4646Z': 'Commerce de gros (commerce interentreprises) de produits pharmaceutiques',
          '6419Z': 'Autres intermédiations monétaires',
          '2815Z': 'arbres de transmission et manivelles',
          '7022Z': 'Conseil pour les affaires et autres conseils de gestion',
          '4651Z':
            "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
          '2540Z': "Fabrication d'armes et de munitions",
          '4661Z': 'Commerce de gros (commerce interentreprises) de matériel agricole',
          '7830Z': 'Autre mise à disposition de ressources humaines',
          '1814Z': 'Reliure et activités connexes',
          '2550A': 'Forge, estampage, matriçage ; métallurgie des poudres',
          '4669C':
            'Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services',
          '8542Z': 'Enseignement supérieur',
          '1520Z': 'Fabrication de chaussures',
          '1392Z': "fabrication d'articles textiles d'ameublement",
          '4673B':
            "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration",
          '2442Z': "Métallurgie de l'aluminium",
          '4221Z': 'Construction de réseaux pour fluides',
          '6430Z': 'Fonds de placement et entités financières similaires',
          '8899B': 'Action sociale sans hébergement n.c.a.',
          '4332B': 'Travaux de menuiserie métallique et serrurerie',
          '3212Z': "Fabrication d'articles de joaillerie et bijouterie",
          '2410Z': 'Sidérurgie',
          '8425Z': 'Services du feu et de secours',
          '2312Z': 'Façonnage et transformation du verre plat',
          '2320Z': 'Fabrication de produits réfractaires',
          '2432Z': 'Laminage à froid de feuillards',
          '3109A': "Fabrication de sièges d'ameublement d intérieur",
          '2341Z': "Fabrication d'articles céramiques à usage domestique ou ornemental",
          '7312Z': 'Régie publicitaire de médias',
          '2344Z': "Fabrication d'autres produits céramiques à usage technique",
          '2311Z': 'Fabrication de verre plat',
          '4674B':
            'Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage',
          '1103Z': 'Fabrication de cidre et de vins de fruits',
          '2594Z': 'Fabrication de vis et de boulons',
          '9420Z': 'Activités des syndicats de salariés',
          '0910Z': "Activités de soutien à l'extraction d'hydrocarbures",
          '1811Z': 'Imprimerie de journaux',
          '2893Z': "Fabrication de machines pour l'industrie agro-alimentaire",
          '6820A': 'Location de logements',
          '1399Z': "Fabrication d'autres textiles n.c.a.",
          '4213A': "Construction d'ouvrages d'art",
          '2894Z': 'Fabrication de machines pour les industries textiles',
          '4643Z': "Commerce de gros (commerce interentreprises) d'appareils électroménagers",
          '4711D': 'Supermarchés',
          '4645Z':
            'Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté',
          '4531Z': "Commerce de gros d'équipements automobiles",
          '8211Z': 'Services administratifs combinés de bureau',
          '3320D':
            "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
          '8412Z':
            'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale',
          '4751Z': 'Commerce de détail de textiles en magasin spécialisé',
          '0121Z': 'Culture de la vigne',
          '1414Z': 'Fabrication de vêtements de dessous',
          '8810B':
            "Accueil ou accompagnement sans hébergement d'adultes handicapés ou de personnes âgées",
          '4613Z': 'Intermédiaires du commerce en bois et matériaux de construction',
          '9104Z': 'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
          '4759B': "Commerce de détail d'autres équipements du foyer",
          '9512Z': 'Réparation d équipements de communication',
          '4762Z': 'Commerce de détail de journaux et papeterie en magasin spécialisé',
          '4635Z': 'Commerce de gros (commerce interentreprises) de produits à base de tabac',
          '4765Z': 'Commerce de détail de jeux et jouets en magasin spécialisé',
          '0149Z': "Élevage d'autres animaux",
          '4616Z':
            'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir',
          '6619B':
            'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.',
          '4778C': 'Autres commerces de détail spécialisés divers',
          '2591Z': 'Fabrication de fûts et emballages métalliques similaires',
          '4791A': 'Vente à distance sur catalogue général',
          '0129Z': 'Autres cultures permanentes',
          '2444Z': 'Métallurgie du cuivre',
          '4641Z': 'Commerce de gros (commerce interentreprises) de textiles',
          '4920Z': 'Transports ferroviaires de fret',
          '7211Z': 'Recherche-développement en biotechnologie',
          '1724Z': 'Fabrication de papiers peints',
          '7220Z': 'Recherche-développement en sciences humaines et sociales',
          '1071A': 'Fabrication industrielle de pain et de pâtisserie fraîche',
          '4644Z':
            "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien",
          '2319Z':
            "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
          '3240Z': 'Fabrication de jeux et jouets',
          '3831Z': "Démantèlement d'épaves",
          '4299Z': "Construction d'autres ouvrages de génie civil n.c.a.",
          '5110Z': 'Transports aériens de passagers',
          '3250B': 'Fabrication de lunettes',
          '1200Z': 'Fabrication de produits à base de tabac',
          '3311Z': "Réparation d'ouvrages en métaux",
          '1086Z': "Fabrication d'aliments homogénéisés et diététiques",
          '8413Z': 'Administration publique (tutelle) des activités économiques',
          '0119Z': 'Autres cultures non permanentes',
          '8531Z': 'Enseignement secondaire général',
          '5222Z': 'Services auxiliaires des transports par eau',
          '4652Z':
            "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication",
          '5223Z': 'Services auxiliaires des transports aériens',
          '4329A': "Travaux d'isolation",
          '1412Z': 'Fabrication de vêtements de travail',
          '8810C': 'Aide par le travail',
          '3091Z': 'Fabrication de motocycles',
          '9103Z':
            'Gestion des sites et monuments historiques et des attractions touristiques similaires',
          '3092Z': 'Fabrication de bicyclettes et de véhicules pour invalides',
          '9412Z': 'Activités des organisations professionnelles',
          '4110A': 'Promotion immobilière de logements',
          '4669A': 'Commerce de gros (commerce interentreprises) de matériel électrique',
          '4120A': 'Construction de maisons individuelles',
          '9603Z': 'Services funéraires',
          '6190Z': 'Autres activités de télécommunication',
          '6202A': 'Conseil en systèmes et logiciels informatiques',
          '3314Z': "Réparation d'équipements électriques",
          '1102B': 'Vinification',
          '4634Z': 'Commerce de gros (commerce interentreprises) de boissons',
          '1039A': 'Autre transformation et conservation de légumes',
          '9601A': 'Blanchisserie-teinturerie de gros',
          '7729Z': "Location et location-bail d'autres biens personnels et domestiques",
          '1039B': 'Transformation et conservation de fruits',
          '1072Z': 'Fabrication de biscuits, biscottes et pâtisseries de conservation',
          '2332Z': 'Fabrication de briques, tuiles et produits de construction, en terre cuite',
          '1102A': 'Fabrication de vins effervescents',
          '2451Z': 'Fonderie de fonte',
          '1330Z': 'Ennoblissement textile',
          '0124Z': "Élevage d'autres bovins et de buffles",
          '1511Z': 'Apprêt et tannage des cuirs ; préparation et teinture des fourrures',
          '2453Z': 'Fonderie de métaux légers',
          '2454Z': "Fonderie d'autres métaux non ferreux",
          '1107B': 'Production de boissons rafraîchissantes',
          '2452Z': "Fonderie d'acier",
          '1320Z': 'Tissage',
          '1083Z': 'Transformation du thé et du café',
          '4312A': 'Travaux de terrassement courants et travaux préparatoires',
          '1051B': 'Fabrication de beurre',
          '2434Z': 'Tréfilage à froid',
          '2331Z': 'Fabrication de carreaux en céramique',
          '1105Z': 'Fabrication de bière',
          '2314Z': 'Fabrication de fibres de verre',
          '9601B': 'Blanchisserie',
          '1032Z': 'Préparation de jus de fruits et légumes',
          '4312B': 'Travaux de terrassement spécialisés ou de grande masse',
          '2443Z': "Métallurgie du plomb, du zinc ou de l'étain",
          '1031Z': 'Transformation et conservation de pommes de terre',
          '9609Z': 'Autres services personnels n.c.a.',
          '1711Z': 'Fabrication de pâte à papier',
          '1052Z': 'Fabrication de glaces et sorbets',
          '4632A': 'Commerce de gros (commerce interentreprises) de viandes de boucherie',
          '3011Z': 'Construction de navires et de structures flottantes',
          '1393Z': 'Fabrication de tapis et moquettes',
          '2571Z': 'Fabrication de coutellerie',
          '4725Z': 'Commerce de détail de boissons en magasin spécialisé',
          '2342Z': "Fabrication d'appareils sanitaires en céramique",
          '4334Z': 'Travaux de peinture et verrerie',
          '4399A': "Travaux d'étanchéification",
          '5629B': 'Autres services de restauration n.c.a.',
          '3213Z': "Fabrication d'articles de bijouterie fantaisie et articles similaires",
          '7731Z': 'Location et location-bail de machines et équipements agricoles',
          '4399C': 'Travaux de maçonnerie générale et gros œuvre de bâtiment',
          '1071B': 'Cuisson de produits de boulangerie',
          '5629A': 'Restauration collective sous contrat',
          '1013B': 'Charcuterie',
          '2370Z': 'Taille, façonnage et finissage de pierres',
          '2612Z': 'Fabrication de cartes électroniques assemblées',
          '2573A': 'Fabrication de moules et modèles',
          '3040Z': 'Construction de véhicules militaires de combat',
          '6810Z': 'Activités des marchands de biens immobiliers',
          '3211Z': 'Frappe de monnaie',
          '7311Z': 'Activités des agences de publicité',
          '1420Z': "Fabrication d'articles en fourrure",
          '2660Z':
            "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
          '4632B': 'Commerce de gros (commerce interentreprises) de produits à base de viande',
          '0891Z': "Extraction des minéraux chimiques et d'engrais minéraux",
          '2895Z': 'Fabrication de machines pour les industries du papier et du carton',
          '4222Z': 'Construction de réseaux électriques et de télécommunications',
          '0145Z': "Élevage d'ovins et de caprins",
          '3320B': 'Installation de machines et équipements mécaniques',
          '6399Z': "Autres services d'information n.c.a.",
          '4662Z': 'Commerce de gros (commerce interentreprises) de machines-outils',
          '2369Z': "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
          '1071D': 'Pâtisserie',
          '7740Z':
            "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
          '1104Z': "Production d'autres boissons fermentées non distillée",
          '8230Z': 'Organisation de foires, salons professionnels et congrès',
          '2363Z': "Fabrication de béton prêt à l'emploi",
          '2431Z': 'Étirage à froid de barres',
          '4781Z': 'Commerce de détail alimentaire sur éventaires et marchés',
          '3900Z': 'Dépollution et autres services de gestion des déchets',
          '1391Z': "Fabrication d'étoffes à mailles",
          '2752Z': "Fabrication d'appareils ménagers non électriques",
          '9602B': 'Soins de beauté',
          '0322Z': 'Aquaculture en eau douce',
          '2891Z': 'Fabrication de machines pour la métallurgie',
        },
      },
    },
    networkDetails: {
      CO2Emisions: 'Émissions de CO2 (g/kWh)',
      cogeneration: 'Cogénération',
      cogenerationElectricity: 'Cogénération (électricité produite en MWh/an)',
      coldDelivery: 'Livraison de froid (MWh/an)',
      coldProductionPercentBySource: {
        absorption: 'Refroidissement par absorption: {percent}%',
        compression: 'Refroidissement par compression: {percent}%',
        heatpump: 'Pompe à chaleur: {percent}%',
        other: 'Autre: {percent}%',
        passive: 'Refroidissement par froid passif: {percent}%',
      },
      coldProductionPercentTitle: 'Mix énergétique (% de la puissance): ',
      communeName: 'Nom de la commune',
      heatProductionPercentBySource: {
        biogas: 'Biogaz: {percent}%',
        biomass: 'Biomasse: {percent}%',
        coal: 'Charbon: {percent}%',
        domesticFuel: 'Fioul domestique: {percent}%',
        electricalBoiler: 'Chaudières électrique: {percent}%',
        gas: 'Gaz naturel: {percent}%',
        geothermal: 'Géothermie: {percent}%',
        heatpump: 'Pompe à chaleur: {percent}%',
        heavyFuel: 'Fioul lourd: {percent}%',
        industrialWasteHeat: 'Chaleur fatale industrielle: {percent}%',
        internalWaste: 'Déchêts internes: {percent}%',
        lpg: 'GPL: {percent}%',
        other: 'Autre: {percent}%',
        otherReneweable: 'Autres EnR&R: {percent}%',
        otherWasteHeat: 'Autres chaleur fatale: {percent}%',
        solarThermal: 'Solaire thermique: {percent}%',
        wasteIncineration: "Usine d'incinération: {percent}%",
      },
      heatProductionPercentTitle: 'Mix énergétique (% de la production annuelle): ',
      heatTransferFluidPercentByType: {
        hotWater: 'Eau chaude: {percent}%',
        overHeatedWater: 'Eau surchauffée: {percent}%',
        Steam: 'Vapeur: {percent}%',
      },
      heatTransferFluidPercentTitle: 'Fluide caloporteur (% de la puissance): ',
      hotDelivery: 'Livraison de chaud (MWh/an)',
      location: 'Localisation',
      locationSource: 'Source de localisation',
      mainDataSource: 'Source des données principales',
      managingCompany: 'Société gestionnaire',
      name: 'Nom',
      networkLength: 'Longueur du réseau (km)',
      networkSNCUIdentifier: 'Identifiant réseau SNCU',
      networkType: 'Type de réseau',
      rateEnRAndR: 'Taux EnR&R (%)',
    },
    highConsumptionBuilding: {
      details: {
        groundSurface: "Surface de l'emprise (m²)",
        height: 'Hauteur (m)',
        purpose: 'Usage principal',
        surface: 'Surface totale (m²)',
        year: 'Année',
      },
      actions: {
        import: 'Importer le bâtiment',
      },
    },
  },
  resultPage: {
    titleColdSimulation: 'Résultats de la simulation pour une production de froid',
    titleHotSimulation: 'Résultats de la simulation pour une production de chaud',
    titleElectricSimulation: 'Résultats de la simulation pour une production électrique',
    backToSimulations: 'Retour aux simulations',
    backToMap: 'Retour à la carte',
    errorMessage: 'La simulation a échoué avec le message suivant: ',
    colorPopin: {
      title: "Modifier l'affichage de la légende",
      selectLabel: 'Filtrer par indicateur',
      sliderLabel: 'Nombre de nuances à afficher : ',
    },
    lcoeByRenRateGraph: {
      sectionTitle: "Sélection des meilleurs scénarios pour chaque tranche de taux d'EnR&R",
      graph: {
        title: 'Meilleurs LCOE par tranche de taux EnR&R cible',
        abscissa: 'LCOE',
        ordinate: 'Taux EnR&R',
        labelPrefix: 'Taux de couverture',
        mixNumber: 'Mix n° {iterationName}',
      },
    },
    ScenarioComparizonTab: {
      title: 'Comparaison des scénarios',
    },
    ScenarioDetailsTab: {
      title: 'Détails du scénario',
    },
    ScenarioEcoEnvKpiTable: {
      sectionTitle: 'KPIs économiques par source',
      headers: {
        sourceName: 'Source',
        distanceToDistrict: 'Distance au réseau (m)',
        lcoe: 'LCOE (€/MWh)',
        investmentCost: "Coût d'investissement (€)",
        operatingCost: 'Coût de maintenance (€/an)',
        energyConsumptionCost: "Coût de l'énergie (€/an)",
      },
    },
    ScenarioEnergyKpiTable: {
      sectionTitle: 'KPIs énergétiques par source',
      headers: {
        iterationName: 'Itération',
        sourceName: 'Source',
        coverageRate: 'Taux de couverture (%)',
        renMixProportion: 'Part EnR&R du mix (%)',
        power: 'Puissance installée (kW)',
        producedEnergy: 'Energie produite (MWh/an)',
        consumedElectricity: 'Électricité consommée (MWh/an)',
        storageVolume: 'Volume de stockage (m³)',
        loadRate: 'Taux de charge (%)',
        runTime: 'Heures de fonctionnement (h)',
        fullLoadRunTimeEquivalent: 'Heures de fonctionnement équivalent à pleine charge (h)',
        mix: 'Mix n°',
      },
    },
    ScenarioEnergyTimeSeriesGraph: {
      sectionTitle: 'Courbes de charge',
      graph: {
        title: 'Production EnR&R (Énergie Renouvelable et de Récupération) et besoins restants',
        abscissa: 'Puissance',
        ordinate: 'Temps',
        remainingLoadLabel: 'Besoins restants',
      },
      export: {
        sheetName: 'Production et besoins restants',
      },
    },
    ScenarioLoadRatesGraph: {
      sectionTitle: 'Courbes des taux de charge',
      graph: {
        title: 'Taux de charge',
        abscissa: 'Taux de charge',
        ordinate: 'Temps',
      },
    },
    SimulationParametersTable: {
      sectionTitle: 'Rappel des hypothèses',
      sheetName: 'Rappel des hypothèses',
    },
    ScenarioSummaryTable: {
      sectionTitle: 'Tableau de synthèse des scénarios',
      exportSheetName: 'Synthèse du scénario|Synthèse des scénarios',
      energyMix: {
        sectionTitle: 'Tableau de synthèse des scénarios des mixes énergétiques',
      },
      headers: {
        autoConsumptionRate: "Taux d'autoconsommation (%)",
        autoConsumptionZoneName: "Zone d'autoconsommation",
        autoProductionRate: "Taux d'autoproduction (%)",
        batteryCapacity: 'Capacité de la batterie (kWh)',
        co2: 'CO2 par énergie produite (g/kWh)',
        constraintsReachedBySource: "Contraintes d'exploitation atteintes",
        coverageRate: 'Taux de couverture (%)',
        investmentCost: "Coût d'investissement (€)",
        iteration: 'Itération',
        lcoe: 'LCOE global (€/MWh)',
        mix: 'Mix n°',
        operatingCost: 'Coût de maintenance (€/an)',
        panelPeakPower: 'Puissance totale installée (kW)',
        producedEnergy: 'Énergie produite (MWh/an)',
        renMix: 'Mix énergetique EnR&R (%)',
        renRate: 'Taux EnR&R global (%)',
        roofCoverageRate: 'Taux de couverture du toit (%)',
        selectForCompare: 'Selectionner pour la comparaison',
        storage: 'Présence stockage',
        summerEnergyNeedsRatio: "Ratio des besoins d'été sur besoin totaux (%)",
        tertiaryEnergyNeedsRatio: 'Ratio des besoins liés au tertiaire (%)',
        totalNeeds: 'Besoins totaux (MWh/an)',
      },
      compareIterationsButton: 'Comparer les itérations',
      constraintsWarning: "Contraintes d'exploitation atteintes pour: ",
      energyMixLeftOver: 'appoint',
      exploitationConstraint: {
        reached: "Contrainte d'exploitations non respectée pour {name}: ",
        threshold: 'Valeur autorisée: ',
        actual_value: 'Valeur atteinte: ',
      },
      filterConstraintsReached:
        "Afficher uniquement les itérations où les seuils des contraintes d'exploitations n'ont pas été atteints",
      displayedIterationsInChart:
        'Les lignes en gras correspondent aux mixes affichés dans le graphe.',
    },
    ScenarioNeedsGraph: {
      sectionTitle: 'Focus sur les besoins',
      graph: {
        title: {
          cooling: 'Besoins de froid du quartier',
          heating: 'Besoins de chaud du quartier',
          electric: "Besoins d'électricité de la zone: {name}",
        },
        abscissa: 'Puissance',
        ordinate: 'Temps',
        legend: {
          cooling: 'Besoins de froid',
          heating: 'Besoins de chaud',
          electric: "Besoins d'électricité",
        },
      },
    },
    ElectricLcoeGraph: {
      sectionTitle: 'Lcoe par mode de dimensionnement',
      kpis: {
        autoConsumptionRate: "Taux d'autoconsommation: {rate}%",
        autoProductionRate: "Taux d'autoproduction: {rate}%",
        daytimeNeedsRatio: 'Part des besoins entre 10h et 18h',
        iterationNameWithoutStorage: 'Itération {index} sans stockage',
        iterationNameWithStorage: 'Itération {index} avec stockage',
        lcoe: 'LCOE: {lcoe}€/MWh',
        residentialNeedsRatio: "Part des besoins d'habitation",
        roofCoverageRate: 'Taux de couverture du toit: {rate}%',
        storage: 'Présence de batteries: {storage}',
        tertiaryNeedsRatio: 'Part des besoins tertiaire',
      },
      panelSizing: {
        bestLcoe: 'Meilleur LCOE',
        bestAutoConso: 'Meilleure autoconsommation',
        bestAutoProd: 'Meilleure autoproduction',
      },
      graph: {
        ordinate: 'LCOE',
        abscissa: 'Mode de dimensionnement',
      },
    },
    ElectricLoadGraph: {
      sectionTitle: 'Besoins électriques horaires de la zone',
      graph: {
        yAxisLegend: 'Energie',
        dataLegend: "Besoins d'électricité",
      },
    },
    ElectricProductionGraph: {
      sectionTitle: 'Production électrique',
      graph: {
        yAxisLegend: 'Energie',
        dataLegend: "Production d'Électricité",
      },
    },
  },
  ErrorPage: {
    404: {
      message: 'Page not found',
      text: "La page à laquelle vous tentez d'accéder n'existe pas ou a été supprimée.\n\nPour tout problème contactez ",
    },
    500: {
      message: 'Internal server error',
      text: "Une erreur s'est produite côté serveur. Si le problème persiste contactez ",
    },
  },
};

export default fr;
