import { customIcons } from '@/components/icons/customIcons';
import { urbanPrintTheme } from '@efficacity/components-library-vuejs3';
import * as vuetify from 'vuetify';
import { mdi } from 'vuetify/iconsets/mdi';
import { en, fr } from 'vuetify/locale';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

export function createVuetify(locale: string) {
  const buildLocaleSettings: () => any = () => {
    if (locale === 'en') {
      return { locale: 'en', fallback: 'en', messages: { en } };
    } else if (locale === 'fr' || locale === 'test') {
      return { locale: 'fr', fallback: 'en', messages: { fr } };
    } else {
      throw new Error('unknown locale');
    }
  };

  return vuetify.createVuetify({
    locale: buildLocaleSettings(),
    theme: {
      defaultTheme: 'energyMapperTheme',
      themes: {
        energyMapperTheme: {
          ...urbanPrintTheme,
          colors: {
            ...urbanPrintTheme.colors,
            'on-surface-accent': urbanPrintTheme.colors.primary,
          },
        },
      },
    },
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdi,
        custom: customIcons,
      },
    },
  });
}
