<script setup lang="ts">
import { allLocales, getLocale, switchLocaleAndReload } from '@/plugins/i18n';
import { ref } from 'vue';

const langItems = allLocales.map((locale) => ({ title: locale, value: locale }));

const locale = ref(getLocale());

function changeLanguage() {
  switchLocaleAndReload(locale.value);
}
</script>

<template>
  <v-select
    v-model="locale"
    :items="langItems"
    bg-color="white"
    hide-details
    density="compact"
    class="lang-selector"
    @update:model-value="changeLanguage"
  />
</template>

<style lang="scss">
#app {
  .lang-selector {
    display: inline-block;
    width: auto;
    margin-inline-start: 16px;
    vertical-align: middle;
  }
}
</style>
